import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment/moment'
//引入字体样式
import '@/assets/font/font.css'
//导入路由导航
import '@/permission'
//px-to-rem适配
import 'amfe-flexible'
//重置css样式
import 'normalize.css'
//公共样式
import '@/assets/css/index.css'
import {
  Button, Form,
  FormItem, Input, Progress, Table,
  TableColumn,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Progress);
Vue.use(Table);
Vue.use(TableColumn);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
